import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/SiteUser";

export default defineNuxtRouteMiddleware((_from) => {
  const userStore = useUserStore();
  const userData = storeToRefs(userStore);
  const cookieLoggedIn = useCookie("42kit_loggedIn");
  if (!cookieLoggedIn.value) {
    const redirectFrom = _from.fullPath;
    const redirectCookie = useCookie("redirectPath");
    redirectCookie.value = redirectFrom;
    return navigateTo("/auth/signin", { redirectCode: 302 });
  }
});
